import PageLayout from "../components/Shared/PageLayout";
import * as React from "react";
import { EEDAnimation, IEDPageParallaxBackgroundImage } from "@trailblazer-game/ed-webkit";
import dynamic from "next/dynamic";
import SPPageLoader from "../components/SPComponents/SPPageLoader";

const NotFound = dynamic(() => import("../components/Pages/NotFound/NotFound"), {
    suspense: false,
    ssr: false,
    loading: () => <SPPageLoader in={true} />,
})


const PARALLAX_IMAGES: IEDPageParallaxBackgroundImage[] = [
    {
        src: "/supercontent/login.jpg",
        size: "cover",
        position: "center",
        animation: {
            animations: [ EEDAnimation.FadeIn ],
            ease: 'ease-in'
        }
    }
];

export default function Page404() {
    return <PageLayout backgroundImages={PARALLAX_IMAGES}>
        <NotFound />
    </PageLayout>
}

Page404.metaData = {
    title: 'Superplay - Not found'
}